import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import Container from '../Components/Container';
import InfoBtn from '../Components/InfoBtn';
import api from '../Api';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import Brands from '../Components/Slider/Brands';
import Numbers from '../Components/Slider/Numbers';
import ModeloBanner from './../Assets/2023-11/about-model.webp';
import RegisterForm from '../Components/RegisterForm';
import Moment from 'moment';

const Selective = () => {
	let { slug } = useParams();
	const [selective, setSelective] = useState(false);
	const [error, setError] = useState(false);
  
	useEffect(() => {
		api.getSelective(slug)
			.then(response => {
				setSelective(response.data);
			})
			.catch(error => {
				console.log(error);
				setError(true);
			});
	}, [slug]);

	if (error === true) {
		return (
			<div>
				<Helmet>
					<title>{'Oops! Houve um erro. - ' + process.env.REACT_APP_NAME}</title>
				</Helmet>
				<p>Houve um erro ao encontrar a seletiva! Entre em contato com nossa equipe no WhatsApp abaixo, lá você também poderá tirar todas as informações necessárias da Seletiva e fazer seu cadastro na hora!</p>
			</div>
		);
	}

	return (
		<div itemScope itemType="https://schema.org/Event">
			<Helmet>
				<title>{selective.name ?? process.env.REACT_APP_SLOGAN + ' - ' + process.env.REACT_APP_NAME}</title>
				<meta title="description" content={selective.description ?? process.env.REACT_APP_DESCRIPTION} />
				<link rel="canonical" href={process.env.REACT_APP_URL} />
			</Helmet>

			<header className="w-full">
				<figure className="w-full lg:w-3/4 z-20 relative shadow-lg">
					{selective.cover &&
						<img itemProp="thumbnail" src={selective.cover} alt={selective.name} className="max-lg:h-[300px] object-cover object-center w-full" />
					}
					<figcaption className="bg-orange-500 p-4 max-lg:w-full lg:absolute lg:left-4 bottom-0 font-poppins font-semibold text-white text-4xl lg:text-6xl">
						{selective.address && selective.address.city_name && selective.address.federated_unit &&
							<h1 itemProp="location" itemScope itemType="https://schema.org/Place">
								<span itemProp="addressLocality">{selective.address.city_name}</span> - <span itemProp="addressRegion">{selective.address.federated_unit}</span>
							</h1>
						}
					</figcaption>
				</figure>
				<figure className="w-4/5 absolute right-0 top-0 overflow-hidden bg-sky-700 z-0 max-lg:hidden">
					{selective.cover &&
						<img src={selective.cover} alt={selective.name} className="blur-lg w-full" />
					}
				</figure>
			</header>

			<div className="bg-white rounded-md w-full lg:min-h-[580px] lg:w-[420px] lg:fixed shadow-lg lg:shadow-xl top-24 right-4 z-40 border-2 border-gray-200">
				<RegisterForm />
			</div>

			<article className="relative w-full lg:w-[calc(100%-450px)] py-4 lg:py-16 pr-4 pl-4 lg:pr-0">
				<Container>
					<div className="my-8">
						<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
							{selective.starting_at && selective.show_starting_at !== 'hide' &&
								<InfoBtn helperText="Início do evento:" icon="clock" content={Moment(selective.starting_at).format('DD/MM/YYYY, H:mm')} />
							}
							{selective.ending_at && selective.show_ending_at !== 'hide' &&
								<InfoBtn helperText="Encerramento do evento:" icon="clock" content={Moment(selective.ending_at).format('DD/MM/YYYY, H:mm')} />
							}
							<a href="https://wa.me/5511916427454"><InfoBtn helperText="FALE CONOSCO" icon="whatsapp" content="(11) 91642-7454" /></a>
						</div>
					</div>
					<div className="my-8">
						<h2 className="text-orange-600 text-4xl lg:text-6xl leading-tight font-poppins font-bold mb-4 lg:max-w-4xl">Você pode ser a próxima estrela da Mega Model!</h2>
						{selective.description &&
							<p className="text-gray-700 font-poppins text-2xl bg-gray-100 rounded-r-md p-4 border-l-4 border-gray-800">{selective.description}</p> 
						}
					</div>
				</Container>
			</article>

			<div className="w-full">
				<div className="relative w-full flex items-center justify-center gap-8 flex-col lg:w-[calc(100%-450px)] py-4 lg:py-16 pr-4 pl-4 lg:pr-0">
					<div className="w-full text-center flex items-center gap-4 justify-center flex-col mb-2">
						<div className="max-w-md lg:max-w-lg">
							<h2 className="text-orange-500 font-bold mb-4 text-poppins text-3xl lg:text-5xl">O que é a Seletiva Mega Model?</h2>
							<div className="text-md lg:text-lg">
								<p className="mb-2">A Seletiva Mega Model Group é a oportunidade que você tem, mesmo morando longe de uma de nossas filiais, de ser vista ou visto, e de se tornar a próxima estrela da Mega Model - a maior agência de atores e modelos da América Latina.</p>
								<p>Com esse evento, levamos teu sonho até você e junto os melhores profissionais do mercado para avaliar, selecionar e lançar novos talentos. É a sua chance! Inscreva-se gratuitamente e participe!</p>
							</div>
						</div>
					</div>
					<div className="hidden lg:block w-[420px] rounded-md overflow-hidden shadow-md mb-2">
						<img className="w-full object-contain object-bottom" src={ModeloBanner} alt="Modelo Mega Diversity da Mega Model" />
					</div>
				</div>
			</div>

			<div className="w-full bg-black">
				<article className="relative w-full lg:w-[calc(100%-450px)] py-4 lg:py-16 pr-4 pl-4 lg:pr-0">
					<Container>
						<div className="my-8">
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
								<div className="flex items-center justify-center gap-2 p-4 flex-col">
									<h3 className="text-orange-500 text-bold text-4xl text-poppins">Quem Conhece, confia</h3>
									<p className="text-white text-xl text-center">Depoimentos de quem acreditou e conseguiu! A Mega hoje possui profissionais de níveis nacionais e mundiais!</p>
								</div>
								<div className="w-full">
									<TestimonialSlider />
								</div>
							</div>
						</div>
					</Container>
				</article>
			</div>

			<div className="w-full">
				<div className="relative lg:w-[calc(100%-450px)] py-4 lg:py-16 pr-4 pl-4 lg:pr-0">
					<Container>
						<div className="my-8">
							<h2 className="text-orange-500 font-bold text-3xl lg:text-6xl text-center text-poppins">Empresas que trabalham com a Mega</h2>
							<Brands />
						</div>
					</Container>
				</div>
			</div>
			
			<div className="w-full bg-gray-100">
				<div className="relative lg:w-[calc(100%-450px)] py-4 lg:py-16 pr-4 pl-4 lg:pr-0">
					<Numbers />
				</div>
			</div>
		</div>
	);
};
  
export default Selective;
