import React from "react";

function Title(props) {
    const {name, description, className} = props;
    
    return (
        <div className={[className, 'w-full flex flex-col justify-center items-center text-center gap-2'].join(' ')}>
            <h2 className="text-black text-3xl md:text-5xl lg:text-6xl font-poppins font-semibold">{name}</h2>
            {description &&
                <div className="flex flex-col gap-2 jutify-center items-center">
                    <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
                    <small className="font-poppins text-lg text-gray-800 max-w-lg">{description}</small>
                </div>
            }
        </div>
    );
}

export default Title;