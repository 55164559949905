import SelectSearch from 'react-select-search';

function Select(props) {

    const options = props.options
    const selected = props.selected ?? ''

    if (options) {
        return (
            <div>
                <select
                    id={props.id}
                    name={props.name}
                    className="w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm"
                    value={selected}
                >
                    {options.map((option) => (
                        <option key={'state_' + option.value} value={option.value}>{option.name}</option>
                    ))}
                </select>
            </div>
        );
    }
}

export default Select;