function NumberCard(props) {
    return (
        <div className="h-full w-full bg-slate-200 rounded-md flex flex-col items-center justify-items-center text-center p-6 relative gap-1">
            <h3 className="text-orange-500 font-poppins font-bold text-3xl lg:text-5xl">{props.number + props.append ?? ''}</h3>
            {props.description &&
                <strong className="text-gray-900 font-semibold font-poppins text-sm lg:text-md max-w-[200px]">{props.description}</strong>
            }
        </div>
    );
}

export default NumberCard;