function InfoBtn(props) {
    const {bg, helperText, icon, content} = props;

    const icons = {
        clock: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 5.8V13L17.8 15.4M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z" stroke="#171717" stroke-width="2" stroke-linecap="round" strokeLineJoin="round"/>
                </svg>`,
        whatsapp: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 11.3012C24 17.5428 18.7256 22.6025 12.2181 22.6025C10.1523 22.6025 8.21147 22.0921 6.52293 21.1963L0 23.2L2.12667 17.1366C1.05387 15.4337 0.436 13.4364 0.436 11.3012C0.436 5.05966 5.71093 0 12.2181 0C18.7261 0 24 5.05966 24 11.3012ZM12.2181 1.7998C6.75573 1.7998 2.31253 6.06216 2.31253 11.3012C2.31253 13.3802 3.0136 15.3056 4.19947 16.8718L2.96187 20.4005L6.76853 19.231C8.33253 20.2237 10.2061 20.8027 12.2184 20.8027C17.68 20.8027 22.124 16.5408 22.124 11.3018C22.124 6.06268 17.6803 1.7998 12.2181 1.7998ZM18.1677 13.904C18.0949 13.7888 17.9027 13.7192 17.6141 13.5808C17.3251 13.4423 15.9048 12.7719 15.6408 12.6798C15.376 12.5875 15.1829 12.5411 14.9907 12.8183C14.7984 13.0956 14.2448 13.7192 14.076 13.904C13.9075 14.0894 13.7392 14.1126 13.4501 13.9739C13.1616 13.8355 12.2312 13.5426 11.128 12.5991C10.2696 11.865 9.68987 10.9587 9.52133 10.681C9.35307 10.4039 9.50373 10.2541 9.648 10.1162C9.77813 9.99198 9.93707 9.79272 10.0813 9.63109C10.2261 9.46921 10.2741 9.35398 10.3699 9.1689C10.4667 8.98407 10.4184 8.82245 10.3459 8.6835C10.2739 8.54508 9.69573 7.18195 9.4552 6.62721C9.21467 6.07299 8.9744 6.16527 8.8056 6.16527C8.63733 6.16527 8.44453 6.14207 8.252 6.14207C8.05947 6.14207 7.7464 6.21141 7.4816 6.48852C7.21707 6.76589 6.4712 7.43612 6.4712 8.79899C6.4712 10.1621 7.5056 11.4791 7.6504 11.6637C7.79467 11.8482 9.64773 14.7364 12.5837 15.8456C15.52 16.9543 15.52 16.5844 16.0496 16.538C16.5787 16.4919 17.7579 15.868 17.9995 15.2215C18.2395 14.574 18.2395 14.0195 18.1677 13.904Z" fill="#171717"/>
                </svg>`,
    };

    return (
        <div className="font-poppins">
            {helperText &&
                <small className="text-sm text-gray-600">{helperText}</small>
            }
            <div className={`bg-${bg ?? 'gray-200'} p-4 text-lg text-gray-900 font-semibold rounded-md flex flex-row items-center gap-4 justify-start`}>
                {icon &&
                    <div dangerouslySetInnerHTML={{__html: icons[icon]}}></div>
                }
                <span >{content}</span>
            </div>
        </div>
    );
}

export default InfoBtn;