import React from 'react';

function PhotoSlider(props) {
    const childrens = React.Children.toArray(props.children);
    
    return (
        <div className="w-full relative px-8" key={Math.random()}>
            <swiper-container
                autoplay={true}
                slides-per-view={3}
                loop={true}
                navigation={true}
                speed={150}
                style={{"--swiper-theme-color": "#FFF"}}
            >
                {childrens.map((slide, index) => (
                    <swiper-slide key={'swiper_slide_' + Math.random() + '_' + index} lazy="true">
                        {slide}
                    </swiper-slide>
                ))}
            </swiper-container>
        </div>
    );
}

export default PhotoSlider;