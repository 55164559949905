function FormGroup(props) {
    const {children, className} = props;
    const setClass = 'w-full relative mb-2';

    return (
        <div className={[className, setClass].join(' ')}>
            {children}
        </div>
    );
}

export default FormGroup;