import React from 'react';
import { Logos } from '../../Hooks/Website';
import Container from '../Container';
import Section from '../Section';

// Images
function Brands() {
    return (
        <div>
			{Logos.length &&
				<Section className="py-4">
					<Container className="max-lg:p-4">
						<div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
							{Logos.map((logo,index) =>
								<figure className="overflow-hidden rounded-md border-2 border-gray-400 shadow-sm" key={'logos_' + index}>
									<img className="w-full" src={logo} alt={'Marcas que trabalharam conosco - ' + process.env.REACT_APP_NAME} />
								</figure>
							)}
						</div>
						<p className="text-center font-poppins text-gray-800 w-full mt-4 text-lg">Marcas que a Mega trabalha e trabalhou.</p>
					</Container>
				</Section>
			}
        </div>
    );
}

export default Brands;