import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'tw-elements';
import 'swiper/css/bundle';

// Router
import {
	Route,
	BrowserRouter,
	Routes
} from "react-router-dom";

// App routes
import AppLayout from './Layouts/AppLayout';
import Home from './Pages/Home';
import Selective from './Pages/Selective';
import ScrollToTop from './Hooks/ScrollToTop';

// Slider
import { register } from 'swiper/element/bundle';
register();

export default function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<AppLayout />}>
					<Route index element={<Home />} />
					<Route path="selective/:slug" element={<Selective />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
