import { Outlet } from "react-router-dom";
import SocialIcon from "../Components/SocialIcon";
import { Link } from "react-router-dom";

const AppLayout = () => {
    return (
        <>
            <div className="top w-full py-4 lg:fixed z-[100]">
                <div className="relative w-full flex flex-row items-cemter justify-between text-white navbar">
                    <div className="app-toolbar-logo relative h-[60px] w-[60px] lg:w-[60%] bg-black text-white flex items-center px-4">
                        <h1 className="max-lg:hidden font-poppins text-lg">Seletiva <strong>Mega</strong>Model</h1>
                    </div>
                    <div className="relative flex flex-row items-center pr-4 space-x-4">
                        <a href="https://facebook.com/megamodelseletiva" className="p-2 rounded-md hover:bg-gray-100 hidden lg:block">
                            <svg width="38" height="38" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M37.5547 0.370056H6.44531C2.89166 0.370056 0 3.26172 0 6.81537V37.9247C0 41.4784 2.89166 44.3701 6.44531 44.3701H19.4219V28.8154H14.2656V21.081H19.4219V15.8388C19.4219 11.5738 22.8913 8.10443 27.1562 8.10443H34.9766V15.8388H27.1562V21.081H34.9766L33.6875 28.8154H27.1562V44.3701H37.5547C41.1083 44.3701 44 41.4784 44 37.9247V6.81537C44 3.26172 41.1083 0.370056 37.5547 0.370056Z" fill="#171717"/>
                            </svg>
                        </a>
                        <a href="https://instagram.com/megamodelseletiva" className="p-2 rounded-md hover:bg-gray-100 hidden lg:block">
                            <svg width="38" height="38" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.25 0.370056H13.75C6.15725 0.370056 0 6.52731 0 14.1201V30.6201C0 38.2128 6.15725 44.3701 13.75 44.3701H30.25C37.8428 44.3701 44 38.2128 44 30.6201V14.1201C44 6.52731 37.8428 0.370056 30.25 0.370056ZM39.875 30.6201C39.875 35.9276 35.5575 40.2451 30.25 40.2451H13.75C8.4425 40.2451 4.125 35.9276 4.125 30.6201V14.1201C4.125 8.81256 8.4425 4.49506 13.75 4.49506H30.25C35.5575 4.49506 39.875 8.81256 39.875 14.1201V30.6201Z" fill="#171717"/>
                                <path d="M22 11.3701C15.9253 11.3701 11 16.2953 11 22.3701C11 28.4448 15.9253 33.3701 22 33.3701C28.0748 33.3701 33 28.4448 33 22.3701C33 16.2953 28.0748 11.3701 22 11.3701ZM22 29.2451C18.2105 29.2451 15.125 26.1596 15.125 22.3701C15.125 18.5778 18.2105 15.4951 22 15.4951C25.7895 15.4951 28.875 18.5778 28.875 22.3701C28.875 26.1596 25.7895 29.2451 22 29.2451Z" fill="#171717"/>
                                <path d="M33.825 12.0108C34.6345 12.0108 35.2908 11.3546 35.2908 10.5451C35.2908 9.73555 34.6345 9.07931 33.825 9.07931C33.0155 9.07931 32.3592 9.73555 32.3592 10.5451C32.3592 11.3546 33.0155 12.0108 33.825 12.0108Z" fill="#171717"/>
                            </svg>
                        </a>
                        <Link to="/#seletivas" reloadDocument preventScrollReset={true} className="p-2 flex flex-row items-center rounded-md space-x-2 hover:cursor-pointer hover:bg-gray-100">
                            <span className="font-poppins font-semibold text-black text-lg uppercase">Seletivas</span>
                        </Link>
                    </div>
                </div>
            </div>
            
            <Outlet />
            
            <footer className="text-white bg-black text-center relative w-full flex justify-center flex-col items-center pt-8">
                <div className="container px-6 mb-8">
                    <div className="flex justify-center items-center flex-col lg:flex-row">
                        <div className="flex justify-center">
                            <SocialIcon name="facebook" />
                            <SocialIcon name="instagram" />
                        </div>
                        <ul className="flex justify-center font-poppins text-white text-md">
                            <li><a href="https://soumegamodel.com.br/paginas/termos-de-uso" className="p-4 hover:underline">Termos de Uso</a></li>
                            <li><a href="https://soumegamodel.com.br/paginas/politica-de-privacidade" className="p-4 hover:underline">Politica de Privacidade</a></li>
                        </ul>
                    </div>
                </div>
                <small className="p-4 w-full float-left text-base border-t-2 border-zinc-900">
                    Mega Model © {new Date().getFullYear()} - Todos os Direitos Reservados
                </small>
            </footer>
        </>
    )
};

export default AppLayout;
