function Container(props) {
    const {children, className} = props;

    return (
        <div className={[className, 'container max-w-6xl'].join(' ')}>
            {children}
        </div>
    );
}

export default Container;