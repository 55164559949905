// Jobs Photos
import Job01 from './../Assets/Jobs/jobs-01.webp';
import Job02 from './../Assets/Jobs/jobs-02.webp';
import Job03 from './../Assets/Jobs/jobs-03.webp';
import Job04 from './../Assets/Jobs/jobs-04.webp';
import Job05 from './../Assets/Jobs/jobs-05.webp';
import Job06 from './../Assets/Jobs/jobs-06.webp';
import Job07 from './../Assets/Jobs/jobs-07.webp';
import Job08 from './../Assets/Jobs/jobs-08.webp';
import Job09 from './../Assets/Jobs/jobs-09.webp';
import Job10 from './../Assets/Jobs/jobs-10.webp';
import Job11 from './../Assets/Jobs/jobs-11.webp';
import Job12 from './../Assets/Jobs/jobs-12.webp';
import Job13 from './../Assets/Jobs/jobs-13.webp';
import Job14 from './../Assets/Jobs/jobs-14.webp';
import Job15 from './../Assets/Jobs/jobs-15.webp';
import Job16 from './../Assets/Jobs/jobs-16.webp';
import Job17 from './../Assets/Jobs/jobs-17.webp';
import Job18 from './../Assets/Jobs/jobs-18.webp';
import Job19 from './../Assets/Jobs/jobs-19.webp';
import Job20 from './../Assets/Jobs/jobs-20.webp';

// Niches Photos
import Niche01 from './../Assets/2023-11/nicho-01.webp';
import Niche02 from './../Assets/2023-11/nicho-02.webp';
import Niche03 from './../Assets/2023-11/nicho-03.webp';
import Niche04 from './../Assets/2023-11/nicho-04.webp';
import Niche05 from './../Assets/2023-11/nicho-05.webp';
import Niche06 from './../Assets/2023-11/nicho-06.webp';
import Niche07 from './../Assets/2023-11/nicho-07.webp';
import Niche08 from './../Assets/2023-11/nicho-08.webp';
import Niche09 from './../Assets/2023-11/nicho-09.webp';
import Niche10 from './../Assets/2023-11/nicho-10.webp';
import Niche11 from './../Assets/2023-11/nicho-11.webp';

// Logos
import Logo01 from './../Assets/Logos/avon@small.webp';
import Logo02 from './../Assets/Logos/ca@small.webp';
import Logo03 from './../Assets/Logos/fiat@small.webp';
import Logo04 from './../Assets/Logos/havaianas@small.webp';
import Logo05 from './../Assets/Logos/iguatemi@small.webp';
import Logo06 from './../Assets/Logos/itau@small.webp';
import Logo07 from './../Assets/Logos/lanca-perfume@small.webp';
import Logo08 from './../Assets/Logos/mitsubishi@small.webp';
import Logo09 from './../Assets/Logos/motorola@small.webp';
import Logo10 from './../Assets/Logos/o-boticario@small.webp';

const Jobs = [
    Job01, Job02, Job03, Job04, Job05, Job06, Job07, Job08, Job09, Job10, 
    Job11, Job12, Job13, Job14, Job15, Job16, Job17, Job18, Job19, Job20, 
];

const Niches = [
    Niche01, Niche02, Niche03, Niche04, Niche05, Niche06, Niche07, Niche08, Niche09, Niche10, 
    Niche11,
];

const Logos = [
    Logo01, Logo02, Logo03, Logo04, Logo05, Logo06, Logo07, Logo08, Logo09, Logo10, 
];

export {Jobs, Niches, Logos};