function Section(props) {
    const {children, wrapper, className} = props;
    const setClass = 'w-full relative ' + (wrapper ? 'py-' + wrapper : 'py-16');

    return (
        <section className={[className, setClass].join(' ')}>
            {children}
        </section>
    );
}

export default Section;