import React from 'react';
import NumberCard from '../NumberCard';

// Images
function Numbers() {
    const numbers = [
        {
            number: 500,
            append: '+',
            description: 'Seletivas Realizadas',
        },
        {
            number: 20,
            append: 'k+',
            description: 'Modelos Agenciados',
        },
        {
            number: 15.6,
            append: 'k',
            description: 'Jobs Realizados em 2022',
        },
        {
            number: 20,
            append: 'M',
            description: 'Comissão para Modelos',
        },
    ];
    
    return (
        <div>
			{numbers.length &&
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 px-4 lg:px-0">
                    <div className="pr-8 flex flex-col items-left justify-center gap-y-2 text-center lg:text-left mb-4 lg:mb-0">
                        <h2 className="lg:max-w-[240px] font-poppins font-black text-gray-600 text-3xl">Os números falam por sí só!</h2>
                        <p className="lg:max-w-[420px] font-poppins font-semibold text-gray-800">A Mega Model continua, a cada ano, impactando milhares pessoas através das Seletivas feitas em todo o país. Nosso principal objetivo é encontrar talentos que possam se destacar no mercado.</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        {numbers.map((number,index) =>
                            <div key={'number_' . index}>
                                <NumberCard number={number.number} append={number.append} description={number.description} />
                            </div>
                        )}
                    </div>
                </div>
			}
        </div>
    );
}

export default Numbers;