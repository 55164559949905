import React from 'react';
import Section from '../Components/Section';
import Card from '../Components/Card';
import Container from '../Components/Container';
import Message from '../Components/Message';
import CardPlaceholder from '../Components/CardPlaceholder';
import Api from '../Api';
import HeaderBanner from './../Assets/2023-11/header.webp';
import ModeloBanner from './../Assets/2023-11/about-model.webp';
import BannerVideo from '../Assets/Videos/seletiva-rua-video.mp4';
import Title from '../Components/Title';
import PhotoSlider from '../Components/Slider/PhotoSlider';
import Brands from '../Components/Slider/Brands';
import {Jobs, Niches} from '../Hooks/Website';
import Numbers from '../Components/Slider/Numbers';
import BannerVideoSub from '../Assets/banner-video-sub.jpeg';

function Home() {
    const [selectives, setSelectives] = React.useState(null);

	React.useEffect(() => {
		Api.getSelectives({
			withCredentials: false
		}).then((response) => {
			setSelectives(response.data);
		}).catch(error => {
			console.log(error);
			setSelectives([]);
		});
	}, []);

    return (
		<div className="App">
			<div className="w-full h-auto bg-[#cecece]">
				<img src={HeaderBanner} alt={process.env.REACT_APP_NAME} className="w-full h-[380px] md:h-[520px] lg:h-[620px] xl:h-[720px] object-cover object-bottom" />
				<Container className="h-full">
					<div className="h-full relative">
						<div className="w-full lg:w-[380px] lg:absolute lg:bottom-0 lg:right-0 bg-black p-4 md:p-6 lg:p-8 flex flex-col items-center lg:items-end justify-center lg:justify-end gap-2 md:gap-4 shadow-md">
							<h2 className="font-poppins text-white text-center lg:text-right textl-xl md:text-3xl lg:text-4xl">A maior Seletiva de Talentos da América Latina</h2>
							<a href="#seletivas" className="text-white font-semibold text-poppins bg-orange-500 rounded-sm p-2 lg:p-3 text-sm md:text-lg lg:text-xl uppercase hover:bg-red-500">Veja as Seletivas</a>
						</div>
					</div>
				</Container>
			</div>

			<Section>
				<Container>
					<Numbers />
				</Container>
			</Section>

			<Section wrapper="0">
				<Container>
					<div className="w-full max-lg:flex max-lg:flex-col gap-2 items-center lg:grid lg:grid-cols-2 lg:items-end">
						<div className="hidden lg:block w-[480px] overflow-hidden rounded-md">
							<img className="w-full object-contain object-bottom" src={ModeloBanner} alt="Modelo Mega Diversity da Mega Model" />
						</div>
						<div className="prose w-full text-center lg:text-left">
							<h2 className="text-orange-500 mb-4 text-poppins text-4xl lg:text-6xl max-lg:w-full lg:max-w-[420px]">O que é a Seletiva Mega Model?</h2>
							<div className="bg-gray-700 text-white text-md lg:text-lg p-2 lg:p-8 lg:pb-12">
								<p>A Seletiva Mega Model Group é a oportunidade que você tem, mesmo morando longe de uma de nossas filiais, de ser vista ou visto, e de se tornar a próxima estrela da Mega Model - a maior agência de atores e modelos da América Latina.</p>
								<p>Com esse evento, levamos teu sonho até você e junto os melhores profissionais do mercado para avaliar, selecionar e lançar novos talentos. É a sua chance! Inscreva-se gratuitamente e participe!</p>
							</div>
						</div>
					</div>
				</Container>
			</Section>

			<section className="w-full lg:h-[620px] max-lg:mt-16 relative">
				<Container className="text-2xl md:text-3xl lg:text-5xl max-lg:px-4 font-poppins font-semibold h-full lg:pb-16 flex flex-col items-center lg:items-end justify-center lg:justify-end z-20 relative">
					<div className="bg-orange-500 text-white p-3 lg:p-6 custom-block-shadow z-10">Nós Viajamos o Brasil</div>
					<div className="bg-white text-gray-900 p-3 lg:p-6 lg:mr-8 lg:max-w-[880px] custom-block-shadow">E resgatamos incríveis <span className="text-orange-500">talentos adormecidos</span> pelo país</div>
				</Container>
				<div className="w-full lg:w-9/12 lg-auto lg:h-full lg:absolute -top-8 left-0 bg-lime-500 z-10 lg:overflow-hidden">
					<video autoPlay muted loop className="relative max-lg:hidden lg:absolute left-0 top-0 min-w-full min-h-full max-lg:h-full lg:max-w-full">
						<source src={BannerVideo} type="video/mp4" />
					</video>
					<img src={BannerVideoSub} alt="Seletiva Mega Model" className="w-full h-full object-cover object-top lg:hidden" />
				</div>
				<div className="w-1/2 h-full absolute top-0 right-0 bg-black z-0 max-lg:hidden"></div>
			</section>

			<div className="w-full" id="seletivas">
				<Section>
					<Container className="max-lg:p-4">
						<Title
							name="Veja todas as Seletivas em andamento"
							description="Se sua cidade ainda não está aparecendo aqui, não se preocupe! Adicione esse site na sua homescreen ou fale conosco pelo WhatsApp!"
						/>
						<div className="w-full relative mt-8">
							{!selectives
								? <div className="w-full relative grid grid-cols-2 gap-4">
									<CardPlaceholder />
									<CardPlaceholder />
								</div>
								: selectives.length
									? <div className={'w-full relative grid gap-4 ' + (selectives.length > 1 ? 'grid-cols-2' : 'grid-cols-1')}>
										{selectives.map((selective) => <Card model={selective} key={selective.id} />)}
										</div>
									: <div>
										<Message
											title="Sem seletivas no nomento"
											message="Não encontramos nenhuma seletiva, volte mais tarde para conferir nossas novidades!"
										/>
									</div>
							}
						</div>
					</Container>
				</Section>
			</div>

			{Jobs.length &&
				<Section className="bg-black">
					<Container>
						<div className="flex items-center justify-between flex-col lg:flex-row max-lg:px-4">
							<div className="text-center lg:text-right">
								<h2 className="font-poppins text-orange-500 text-4xl font-semibold">Jobs</h2>
								<p className="font-poppins text-lg text-white">A Mega Model realiza diversos de jobs semanalmente, com toda a diversidade de nichos do mercado publicitário. Hoje há uma demanda enorme para  diversidade!</p>
							</div>
							<div className="slider w-full lg:w-8/12">
								<PhotoSlider>
									{Jobs.map((job, index) => 
										<figure className="p-2 shadow-sm" key={'jobs_' + index}>
											<img src={job} alt={'Jobs Realizados - Foto ' + index + ' - ' + process.env.REACT_APP_NAME} />
										</figure>
									)}
								</PhotoSlider>
							</div>
						</div>
					</Container>
				</Section>
			}

			<div className="w-full">
				<Brands />
			</div>

			{Niches.length &&
				<Section className="bg-black">
					<Container>
						<div className="flex items-center justify-between flex-col lg:flex-row max-lg:px-4">
							<div className="text-center lg:text-right">
								<h2 className="font-poppins text-orange-500 text-4xl font-semibold">Nichos</h2>
								<p className="font-poppins text-lg text-white">{new Date().getFullYear()} traz uma enorme variedade de nichos de mercado como nunca havia tido!</p>
							</div>
							<div className="slider w-full lg:w-8/12">
								<PhotoSlider>
									{Niches.map((niche, index) => 
										<figure className="p-2 shadow-sm" key={'niches_' + index}>
											<img src={niche} alt={'Nichos de Mercado - Foto ' + index + ' - ' + process.env.REACT_APP_NAME} loading="lazy" />
										</figure>
									)}
								</PhotoSlider>
							</div>
						</div>
					</Container>
				</Section>
			}
		</div>
    );
};
  
export default Home;
