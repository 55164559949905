import { forwardRef } from 'react';

export default forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, ...props }) {
    return (
        <input
            {...props}
            type={type}
            className={
                'w-full border-gray-300 border-2 focus:border-blue-500 focus:text-blue-500 rounded-md shadow-sm ' +
                className
            }
        />
    );
});
