import React from 'react';

// Images
import img01 from '../../Assets/Testimonials/bruna-silveira.webp';
import img02 from '../../Assets/Testimonials/elisa-freitas.webp';

function TestimonialSlider() {
    const colors = ['green', 'red', 'blue', 'purple', 'cyan', 'grey', 'orange'];
	const slides = [
		{
			name: "Bruna Silveira",
			photo: img01,
			description: "Não se pergunte: vale a pena? VALE MUITO A PENA. Se é seu sonho, você tem que vir mesmo!",
		},
		{
			name: "Elisa Freitas",
			photo: img02,
			description: "Seja você mesma e não siga padrões!",
		},
	];

    return (
        <div className="w-full relative px-8" key={Math.random()}>
            <swiper-container
                autoplay={true}
                slides-per-view={1}
                loop={true}
                navigation={true}
                speed={150}
                style={{"--swiper-theme-color": "#FFF"}}
            >
                {slides.map((slide, index) => (
                    <swiper-slide key={'swiper_slide_' + Math.random() + '_' + index}>
                        <div className="w-full flex items-center justify-center flex-col">
                            <div className="w-full max-w-[320px] overflow-hidden rounded-md">
                                {slide.photo &&
                                    <div className={'w-full h-32 relative bg-' + colors[index] + '-500 z-10'}>
                                        <div className="rounded-full bg-gray-800 border-white border-4 overflow-hidden border-solid absolute bottom-[-60px] left-[50%] w-[120px] h-[120px] -ml-[60px]">
                                            <img className="w-full h-full object-cover float-left block" src={slide.photo} alt={slide.name} />
                                        </div>
                                    </div>
                                }
                                <div className="w-full relative bg-white p-8 pt-[68px] flex items-center justify-center flex-col gap-2">
                                    <h3 className="text-gray-900 font-bold font-poppins text-xl">{slide.name}</h3>
                                    {slide.description &&
                                        <p className='text-center'>{slide.description}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                ))}
            </swiper-container>
        </div>
    );
}

export default TestimonialSlider;