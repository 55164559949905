function CardPlaceholder() {
    return (
        <div className="card w-full mb-8 relative">
            <div className="w-full h-[220px] rounded-md shadow-md bg-gray-400"></div>
            <div className="relative px-4 -mt-16">
                <div className="bg-white p-6 rounded-md shadow-lg">
                    <div className="flex-items-baseline mb-3">
                        <span className="inline-block w-16 h-8 bg-gray-400 rounded-md animate-pulse"></span>
                        <span className="inline-block ml-2 w-16 h-8 bg-gray-300 rounded-md animate-pulse"></span>
                    </div>
                    <div className="mb-3 bg-gray-200 rounded-4 w-11/12 h-16 rounded animate-pulse"></div>
                    <div className="flex space-x-2 justify-left">
                        <button type="button" className="inline-block px-6 py-2.5 w-1/2 h-12 bg-gray-300 rounded animate-pulse"></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardPlaceholder;